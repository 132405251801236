@import "base";

.screen-cart-container {
    height: calc(100% - 53px);
    padding: 0.5vw 1vw;
    .screen-cart-inner-container {
        @include card($border-radius: 6px);
        height: 100%;
        padding: 0.5vw 1vw;
        .screen-cart-header {
            padding-bottom: 0.5vw;
            justify-content: space-between;
            .btn-edit {
                margin-right: 15px;
                border: 1px solid $color-blue;
                background-color: $color-white;
                color: $color-blue;
            }
        }
        .screen-cart-content {
            @include card($border-radius: 6px);
            padding: 0.5vw 0;
            > div:not(.cart-group-details):not(.row-files),
            > h2 {
                padding: 0 1vw;
            }
            h2 {
                font-size: 18px;
                font-weight: 700;
            }
            .row .col {
                width: calc(100% / 5 - 15px);
                margin-right: 15px;
            }
            .cart-group-details {
                background-color: $color-cream;
                padding: 0.5vw 1vw;
                > .row {
                    &:not(:last-child) {
                        border-bottom: 1px solid gray;
                        margin-bottom: 0.5vw;
                        padding-bottom: 0.5vw;
                    }
                    > span {
                        font-weight: 300;
                        font-size: 14px;
                        white-space: nowrap;
                        margin-right: 10px;
                        span {
                            font-weight: 500;
                        }
                    }
                }
                .line {
                    height: 15px;
                    width: 1px;
                    background-color: $color-text;
                    margin-right: 10px;
                }
                .working-hour-single {
                    text-transform: capitalize;
                    span {
                        margin-left: 5px;
                    }
                }
            }
            .row-files {
                border-top: 1px solid $color-text;
                border-bottom: 1px solid $color-text;
                margin: 20px 1vw;
                h2 {
                    margin-right: 15px;
                }
                .single-file {
                    margin-right: 16px;
                    a {
                        font-weight: 700;
                    }
                }
            }
        }
    }
}
