@import "base";

.inputs-cover-photo-container {
    margin: 10px 0;
    position: relative;
    width: 80px;
    &.disabled {
        .icon {
            cursor: default;
            &:active {
                transform: none;
            }
        }
        .icon.icon-camera {
            display: none;
        }
    }
    .icon.icon-avatar {
        // border: 2px dashed #000000;
        border-radius: 6px;
        height: 80px;
        width: 80px;
        @include shadow-large;
        background-size: cover;
    }
    .icon.icon-camera {
        position: absolute;
        bottom: 0;
        border-radius: 3px;
        right: 0;
        background-color: $color-white;
        background-size: 80%;
        cursor: pointer;
    }
    .icon.icon-add-photo {
        border: 2px dashed #000000;
        border-radius: 6px;
        background-size: 50%;
        height: 80px;
        width: 80px;
    }
}
