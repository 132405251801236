@import "base";

.screen-business-client-form-container {
    height: calc(100% - 53px);
    padding: 0.5vw 1vw;
    .screen-business-client-form-inner-container {
        @include card($border-radius: 6px);
        height: 100%;
        padding: 0.5vw 1vw;
        .screen-business-client-form-header {
            padding-bottom: 0.5vw;
            justify-content: space-between;
            .btn-delete {
                margin-right: 16px;
                background-color: $color-white;
                border: 1px solid $color-red;
                color: $color-text;
            }
        }
        .screen-business-client-form-content {
            @include card($border-radius: 6px);
            padding: 0.5vw 1vw;
            overflow: auto;
            h2 {
                font-size: 18px;
                font-weight: 700;
            }
            .divider {
                height: 1px;
                width: 100%;
                background-color: $color-text;
                margin-top: 15px;
            }
            > .row {
                flex-wrap: wrap;
                align-items: flex-end;
                > .col {
                    width: calc(100% / 3 - 15px);
                    margin-right: 15px;
                }
            }
            .row-user {
                > .col {
                    width: calc(100% / 3 - 15px);
                    margin-right: 15px;
                }
                .icon.icon-remove {
                    height: 20px;
                    width: 20px;
                    margin-top: 25px;
                }
            }
            .row-add-user {
                align-items: center;
                font-weight: 700;
                padding: 10px 0;
                cursor: pointer;
                .icon.icon-plus {
                    margin-right: 16px;
                }
            }
            .row-files-container {
                align-items: center;
                padding: 15px 0 0;
                .icon.icon-plus {
                    margin: 0 16px;
                }
                .single-file {
                    margin-right: 16px;
                    a {
                        font-weight: 700;
                    }
                    .icon-remove {
                        height: 15px;
                        width: 15px;
                        margin-left: 5px;
                    }
                }
            }
            .comments-container {
                .row-comments-input {
                    margin: 10px 0;
                    .icon.icon-avatar {
                        height: 38px;
                        width: 38px;
                        margin-right: 16px;
                    }
                    .inputs-text-container {
                        flex: 1;
                        margin: 0;
                    }
                }
            }
            .icon.icon-plus {
                border: 1px dashed $color-blue;
                border-radius: 6px;
                background-size: 70%;
            }
            span .icon-required {
                display: inline-block;
                height: 6px;
                width: 6px;
                margin-bottom: 8px;
                margin-left: 4px;
            }
        }
    }
}
