@import "base";
.inputs-chat-input-container {
    position: relative;
    height: 100%;
    width: 100%;
    align-items: flex-start;
    .cover-photo {
        width: 35px;
        min-width: 35px;
        height: 35px;
        border-radius: 50%;
        margin-right: 5px;

        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    .row-files {
        flex: 1;
        border-radius: 6px 0 0 6px;
        padding: 9px 11px;
        margin: 10px 0px;
        border: 1px solid rgba(0, 52, 112, 0.3);
        border-right: none;
        color: #01102a;
        font-size: 13px;
        height: 36px;
        background-color: #ffffff;
        overflow-x: auto;
        overflow-y: hidden;
        .single-file {
            background-color: $color-blue-light;
            border-radius: 6px;
            padding: 3px 10px;
            margin-right: 10px;
            .icon.icon-remove {
                height: 10px;
                width: 10px;
                margin-top: 5px;
                margin-left: 5px;
            }
        }
    }
    .text-area-container {
        display: flex;
        flex: 1;
        margin: 10px 0px;
    }
    textarea {
        flex: 1;
        resize: none;
        border: 1px solid rgba(0, 52, 112, 0.3);
        border-radius: 6px 0 0 6px;
        border-right: none;
        padding: 10px;
        padding-right: 50px;
        font-size: 13px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        &:active,
        &:focus {
            outline: none;
        }
        &.disabled {
            border: 1px solid rgba(0, 52, 112, 0.15);
            border-right: none;
        }
    }
    .image-upload-input {
        box-shadow: none;
        padding: 0;
    }
    .icon.icon-attach {
        margin-left: 15px;
        height: 25px;
        width: 25px;
    }
    .icon.icon-send {
        border: 1px solid rgba(0, 52, 112, 0.3);
        border-left: none;
        background-size: 70%;
        height: calc(100% - 20px);
        width: 36px;
        border-radius: 0 6px 6px 0;
    }
}
