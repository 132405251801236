@import "base";

.sessions-map-container {
    position: relative;
    .row-buttons {
        position: absolute;
        top: 10px;
        left: 50%;
        transform: translate(-50%);
        .btn-play,
        .btn-center {
            justify-content: center;
            @include card($border-radius: 6px);
            cursor: pointer;
            margin: 0 5px;
            height: 29px;
            width: 29px;
            padding: 3px;
        }
    }
    .marker {
        height: 30px;
        width: 30px;
        border-radius: 50%;
        &.marker-origin {
            background-color: $color-blue;
            &::after {
                content: "A";
            }
        }
        &.marker-destination {
            background-color: $color-blue;
            &::after {
                content: "B";
            }
        }

        &.marker-origin::after,
        &.marker-destination::after {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            width: 100%;
            color: $color-white;
            font-size: 16px;
            font-weight: 500;
        }

        &.marker-active {
            background-color: $color-blue;
        }
    }
}
