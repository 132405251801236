@import "base";

.screen-discount-codes-container {
    height: calc(100% - 53px);
    padding: 0.5vw 1vw;
    .screen-discount-codes-inner-container {
        @include card($border-radius: 6px);
        height: 100%;
        .screen-discount-codes-content {
            height: calc(100% - 53px);
        }
        .screen-discount-codes-footer {
            height: 53px;
            @include card($background: $color-cream);
            .row-stats {
                @include card($background: $color-blue-light, $border-radius: 6px);
                p {
                    margin: 0;
                    padding: 0 16px;
                    line-height: 53px;
                    span {
                        font-weight: 700;
                    }
                    &:not(:last-child) {
                        border-right: 1px solid gray;
                    }
                }
            }
            .row-buttons {
                margin-left: auto;
                margin-right: 16px;
            }
        }
    }
}
