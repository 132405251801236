@import "base";
.sessions-session-info-container {
    @include card($border-radius: 6px);
    margin-bottom: 16px;
    .sessions-session-info-header {
        @include card($border-radius: 6px);
        padding: 0.5vw 1vw;
        justify-content: space-between;
    }
    .sessions-session-info-content {
        padding: 0.5vw 1vw;
        .screen-session-info-inner-content {
            flex-wrap: wrap;
            .row {
                background-color: $color-whiteish;
                border-radius: 6px;
                margin: 15px;
                margin-left: 0;
                padding: 0 15px;
                p {
                    margin: 0;
                    padding: 10px 0;
                    font-size: 0.875rem;
                    line-height: 1.375rem;
                    span {
                        font-weight: 700;
                    }
                }
                .icon-profile {
                    height: 25px;
                    width: 25px;
                    margin-left: 16px;
                }
            }
        }
    }
}
