.popup-content.modal-container-content.DiscountCodeUsers-content {
    width: auto;
    .modal-discount-code-users-container {
        padding: 0.5vw;
        .modal-discount-code-users-header {
            justify-content: space-between;
            h2 {
                font-weight: 700;
                font-size: 22px;
            }
        }
    }
}
