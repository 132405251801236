@import "base";
.screen-reservations-container {
    height: calc(100% - 53px);
    padding: 0.5vw 1vw;
    .screen-reservations-inner-container {
        @include card($border-radius: 6px);
        height: 100%;
    }
    .screen-reservations-content {
        height: calc(100% - 53px);
        .table-container {
            .table-row-cell.status{
                .unreadMessages-indicator {
                    background-color: red;
                    border-radius: 50%;
                    height: 10px;
                    width: 10px;
                }
            }
            .table-row-cell.chat,
            .table-row-cell.notes {
                > div.row {
                    width: 100%;
                    justify-content: space-between;
                }
                .icon-arrow-right-white {
                    height: 25px;
                    width: 25px;
                    margin-right: 14px;
                }
            }
            .table-row-cell.notes {
                padding-right: 30px;
            }
        }
    }
    .screen-reservations-footer {
        height: 53px;
        @include card($background: $color-cream);
        .row-stats {
            @include card($background: $color-blue-light, $border-radius: 6px);
            p {
                margin: 0;
                padding: 0 16px;
                line-height: 53px;
                span {
                    font-weight: 700;
                }
                &:not(:last-child) {
                    border-right: 1px solid gray;
                }
            }
        }
        .row-buttons {
            margin-left: auto;
            .buttons-raised-container {
                height: 40px;
            }
            .icon-export {
                @include shadow-large;
                margin: 0 16px;
                height: 40px;
                width: 40px;
            }
        }
    }
}
