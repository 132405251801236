@import "base";
.inputs-switch-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin-right: 15px;
    .inputs-switch-inner-container {
        width: 56px;
        height: 28px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 18px;
        background-color: #7e7e7e;
        position: relative;
        transition: all 0.3s ease-in-out;
        padding: 0px 5px;
        .inputs-switch-circle {
            height: 24px;
            width: 24px;
            border-radius: 50%;
            position: absolute;
            left: 2px;
            transition: all 0.3s ease-in-out;
            background-color: white;
        }
    }
    &:hover {
        cursor: pointer;
    }
    &.switch-on {
        .inputs-switch-inner-container {
            background-color: $color-blue;
        }
        .inputs-switch-circle {
            left: calc(100% - 26px);
        }
    }
    span {
        color: $color-white;
        font-size: 13px;
        transition: all 0.3s ease-in-out;
        padding-bottom: 3px;
    }
    .label-on{
        margin-left: 3px;
    }

     .label-off{
        margin-right: 3px;
    }

    .switch-label {
        margin-right: 10px;
    }
    .fade-in {
        opacity: 1;
        transform: scale(1);
    }
    .fade-out {
        opacity: 0;
        transform: scale(0);
    }
}
