@import "base";

.shared-sorting-component-container {
    min-width: 200px;
    padding: 5px 10px;
    position: relative;
    .checkboxes-container {
        max-height: 200px;
        overflow-y: auto;
    }
    .buttons-raised-container {
        margin-left: 20px;
    }

    .label-container p {
        margin: 6px 0;
        line-height: 1.25rem;
    }

    h3 {
        font-size: 1rem;
        line-height: 1.25rem;
        font-weight: 700;
        margin: 10px 0;
    }
    p {
        font-size: 0.875rem;
        line-height: 1.25rem;
        margin: 10px 0;
        &.btn-clear {
            cursor: pointer;
            &:active {
                transform: scale(0.95);
            }
        }
    }
    > div:not(:last-child) {
        margin-bottom: 30px;
    }
}

[role="tooltip"].popup-content.popup-shared-sorting-component-content {
    width: auto;
    padding: 0;
}
