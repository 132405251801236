@import "base";

.screen-vehicle-form-container {
    height: calc(100% - 53px);
    padding: 0.5vw 1vw;
    .screen-vehicle-form-inner-container {
        @include card($border-radius: 6px);
        height: 100%;
        padding: 0.5vw 1vw;
        .screen-vehicle-form-header {
            padding-bottom: 0.5vw;
            justify-content: space-between;
            .btn-delete {
                margin-right: 16px;
                background-color: $color-white;
                border: 1px solid $color-red;
                color: $color-text;
            }
        }
        .screen-vehicle-form-content {
            @include card($border-radius: 6px);
            padding: 0.5vw 1vw;
            > .row {
                align-items: flex-end;
                > .col {
                    width: calc(100% / 7 - 15px);
                    margin-right: 15px;
                    span .icon-required {
                        display: inline-block;
                        height: 6px;
                        width: 6px;
                        margin-bottom: 8px;
                        margin-left: 4px;
                    }
                }
            }
        }
    }
}
