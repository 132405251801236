@import "base";
.fleet-vehicle-info-container {
    @include card($border-radius: 6px);
    margin: 16px;
    > div {
        padding: 0 16px;
    }
    .fleet-vehicle-info-header {
        justify-content: space-between;
        @include card($background: $color-blue-light, $border-radius: 6px);
        h3 {
            font-weight: 700;
            margin: 10px 0;
        }
    }
    .fleet-vehicle-info-content {
        p {
            margin: 10px 0;
            span {
                font-weight: 700;
            }
        }
    }
    .fleet-vehicle-info-footer {
        @include card($border-radius: 6px);
        text-align: center;
        cursor: pointer;
        .fleet-vehicle-info-inner-footer {
            flex: 1;
            p {
                font-weight: 700;
                flex: 1;
                margin: 10px 0;
            }
            .icon {
                margin-left: 15px;
            }
            &:active {
                transform: scale(0.98);
            }
        }
    }
    .fleet-vehicle-info-empty {
        text-align: center;
        padding: 15px 0;
        font-weight: 700;
    }
    &.active {
        border: 2px solid $color-blue;
        .fleet-vehicle-info-header {
            border-radius: 0px 0px 6px 6px;
            background-color: $color-blue;
            h3 {
                color: $color-white;
            }
        }
    }
}
