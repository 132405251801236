@import "base";

.table-container {
    min-height: 100%;
    max-height: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    .table-header {
        padding: 10px 16px;
        align-items: flex-end;
        .table-cell {
            font-weight: 500;
            padding-left: 14px;
            &:not(:first-child) {
                border-left: solid 1px transparent;
            }
            .icon-arrow-down {
                min-width: auto;
                margin-left: 10px;
                height: 20px;
                width: 20px;
            }
        }
    }
    .table-content {
        .table-row-container {
            overflow: hidden;
            padding: 0 16px 0;
            transition: all 0.4s ease-in;
            &.active {
                > .table-row-inner-container {
                    margin-bottom: 0;
                }
                > .table-row-children-container {
                    border-color: #00347026;
                    margin-bottom: 5px;
                }
            }
            .table-row-inner-container {
                box-shadow: 0px 1px 4px 0px #00347026;
                border-radius: 6px;
                max-height: 60px;
                padding: 10px 0;
                align-items: stretch;
                .table-cell {
                    white-space: nowrap;
                    overflow: auto;
                    @include remove-scrollbars;
                    padding-left: 14px;
                    &:not(:first-child) {
                        border-left: solid 1px #00347026;
                    }
                }
            }
            .table-row-children-container {
                height: 0;
                overflow: hidden;
                transition: all 0.4s ease-in;
                border-radius: 0 0 6px 6px;
                border: 2px solid transparent;
                border-top: none;
            }
        }
    }
    .table-content-empty {
        justify-content: center;
        align-items: center;
        text-align: center;
    }
}
