@import "base";

.inputs-address-container {
    margin: 10px 0;
    input {
        width: 100%;
        flex: 1;
        border-radius: 5px;
        padding: 9px 11px;
        border: 1px solid rgba(0, 52, 112, 0.3);
        color: $color-text;
        font-size: 13px;
        height: 38px;
    }
    &.invalid {
        border-radius: 6px;
        box-shadow: 0 0 3px $color-red;
        input {
            border-color: $color-white;
        }
    }
}
