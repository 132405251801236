@import "base";

.screen-business-client-container {
    height: calc(100% - 53px);
    padding: 0.5vw 1vw;
    .screen-business-client-inner-container {
        @include card($border-radius: 6px);
        height: 100%;
        padding: 0.5vw 1vw;
        .screen-business-client-header {
            padding-bottom: 0.5vw;
            justify-content: space-between;
        }
    }
    .screen-business-client-content {
        @include card($border-radius: 6px);
        padding: 0.5vw 1vw;
        overflow: auto;
        h2 {
            font-size: 18px;
            font-weight: 700;
        }
        .divider {
            height: 1px;
            width: 100%;
            background-color: $color-text;
            margin-top: 15px;
        }
        .row-user {
            > .col {
                width: calc(100% / 3 - 15px);
                margin-right: 15px;
            }
            .icon.icon-history {
                margin-top: 25px;
            }
        }
        .row-files {
            padding: 15px 0 0;
            a {
                margin: 0 16px;
                font-weight: 700;
            }
        }
        .comments-container {
            .inputs-chat-input-container{
                height: auto;
            }
            .comments-content {
                flex-direction: column-reverse;
                .days-divider {
                    p {
                        font-size: 1.125rem;
                        font-weight: 500;
                        margin: 0;
                        font-size: 14px;
                    }
                    .line {
                        height: 1px;
                        width: 100%;
                        background-color: $color-text;
                        margin-left: 15px;
                        margin-top: 6px;
                    }
                }
                .message {
                    &.is-edit {
                        pointer-events: none;
                        opacity: 0.5;
                    }
                    > p {
                        margin-bottom: 0px;
                        font-weight: 700;
                        font-size: 0.875rem;
                    }
                    .message-content {
                        padding: 0.5vw;
                        position: relative;
                        border-radius: 6px;
                        border: 1px solid $color-text;
                        p {
                            font-size: 0.875rem;
                        }
                        .icon.icon-options {
                            position: absolute;
                            right: 1vw;
                            top: 50%;
                            transform: translateY(-50%);
                            height: 20px;
                            width: 20px;
                        }
                    }
                    .placeholder {
                        width: 35px;
                        min-width: 35px;
                        height: 35px;
                        border-radius: 50%;
                        margin-right: 5px;
                        &.logo {
                            background-position: center;
                            background-repeat: no-repeat;
                            background-size: cover;
                        }
                    }
                    .text {
                        flex: 1;
                        padding: 0 1vw;
                        margin: 8px 0;
                        white-space: pre-line;
                    }

                    span.time {
                        font-size: 0.75rem;
                        margin-left: 15px;
                        color: gray;
                    }
                }
            }
        }
    }
}
