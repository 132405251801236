@import "base";
.header-container {
    background: $color-white;
    @include shadow-small;
    width: 100%;
    height: 53px;
    padding: 0 2vw;
    .header-left {
        flex: 1;
        height: 100%;
        > a,
        > a img {
            height: 100%;
            padding: 2px 0;
        }
    }
    .header-links {
        justify-content: center;
        height: 100%;
        position: relative;
        > a {
            @extend %flex;
            align-items: center;
            text-decoration: none;
            font-size: 1rem;
            height: 100%;
            padding: 0 5px;
            transition: font-weight 0.3s linear;
            &:not(:first-child) {
                margin-left: 50px;
            }
            &.active {
                font-weight: 700;
            }
        }
        .active-route-selector {
            position: absolute;
            height: 0px;
            width: 150px;
            bottom: 0px;
            left: 0px;
            transition: all 0.3s ease-in-out;
            background-color: $color-green;
            &.selector-add-height {
                height: 5px;
            }
        }
    }
    .header-right {
        justify-content: flex-end;
        flex: 1;
        > .icon-avatar {
            height: 35px;
            width: 35px;
        }
    }
}

[role="tooltip"].popup-content.popup-header-options-container-content {
    width: auto;
    padding: 0;
    .popup-header-options-header {
        justify-content: space-between;
        @include card;
        padding: 10px 15px;
        h3 {
            margin: 0;
            font-weight: 700;
            font-size: 18px;
            line-height: 22px;
        }
        .icon-close {
            height: 18px;
            width: 18px;
        }
    }
    .popup-header-options-content {
        padding: 0 15px;
        .row-profile {
            padding: 15px 0;
            .icon-avatar {
                height: 50px;
                width: 50px;
                margin-right: 15px;
            }
            p {
                margin: 0;
                line-height: 25px;
                &.bold {
                    font-weight: 700;
                }
            }
        }
        .line {
            height: 1px;
            width: 100%;
            background-color: gray;
        }
        .row-language {
            .icon {
                margin-right: 15px;
            }
            p {
                font-weight: 700;
            }
            .inputs-switch-container {
                margin: 4px 5px 0;
                .inputs-switch-inner-container {
                    height: 20px;
                    width: 40px;
                    .inputs-switch-circle {
                        height: 16px;
                        width: 16px;
                    }
                }
                &.switch-on .inputs-switch-circle {
                    left: calc(100% - 18px);
                }
            }
        }
        .row-action {
            cursor: pointer;
            .icon {
                margin-right: 15px;
            }
            p {
                font-weight: 700;
            }
            &:active {
                transform: scale(0.98);
            }
        }
    }
}
