@import "base";

.screen-reservation-form-container {
    height: calc(100% - 53px);
    padding: 0.5vw 1vw;
    .screen-reservation-form-inner-container {
        @include card($border-radius: 6px);
        height: 100%;
        padding: 0.5vw 1vw;
        overflow: hidden;
        .screen-reservation-form-header {
            padding-bottom: 0.5vw;
            justify-content: space-between;
            .btn-delete {
                margin-right: 16px;
                background-color: $color-white;
                border: 1px solid $color-red;
                color: $color-text;
            }
        }
        .screen-reservation-form-content {
            @include card($border-radius: 6px);
            padding: 0.5vw 1vw;
            flex: 1;
            overflow: auto;
            h2 {
                font-size: 18px;
                font-weight: 700;
            }
            .divider {
                height: 1px;
                width: 100%;
                background-color: $color-text;
                margin-top: 15px;
            }
            > .row {
                flex-wrap: wrap;
                align-items: flex-end;
                > .col {
                    width: calc(100% / 5 - 15px);
                    margin-right: 15px;
                    &.col-double {
                        width: calc(100% / 2.5 - 15px);
                    }
                    &.col-half {
                        width: calc(100% / 10 - 15px);
                    }

                    .inputs-dropdown-container {
                        .invalid__control {
                            border-radius: 6px;
                            box-shadow: 0 0 3px $color-red;
                            border-color: $color-white;
                        }
                    }
                }
            }
            .row-vehicle {
                align-items: center;
                .icon.icon-remove {
                    height: 20px;
                    width: 20px;
                    margin-top: 25px;
                }
            }
            .row-add-vehicle {
                align-items: center;
                font-weight: 700;
                padding: 10px 0;
                cursor: pointer;
                .icon.icon-plus {
                    margin-right: 16px;
                    border: 1px dashed $color-blue;
                    border-radius: 6px;
                    background-size: 70%;
                }
            }
            span .icon-required {
                display: inline-block;
                height: 6px;
                width: 6px;
                margin-bottom: 8px;
                margin-left: 4px;
            }
        }
    }
}
