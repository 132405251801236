@import "base";
.screen-fleet-container {
    height: calc(100% - 53px);
    padding: 0.5vw 1vw;
    .screen-fleet-inner-container {
        @include card($border-radius: 6px);
        height: 100%;
        .screen-fleet-map {
            @include card($border-radius: 6px);
            flex: 3;
            height: 100%;
        }
        .screen-fleet-vehicles-container {
            flex: 1.4;
            height: 100%;
            .screen-fleet-vehicles-header {
                justify-content: space-between;
                padding: 0 16px;
                h3 {
                    text-transform: uppercase;
                    font-weight: 700;
                }
               
            }
            .screen-fleet-vehicles-content {
                height: calc(100% - 65px);
                overflow: auto;
            }
        }
    }
}
