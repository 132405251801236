@import "base";

.screen-employee-form-container {
    height: calc(100% - 53px);
    padding: 0.5vw 1vw;
    .screen-employee-form-inner-container {
        @include card($border-radius: 6px);
        height: 100%;
        padding: 0.5vw 1vw;
        .screen-employee-form-header {
            padding-bottom: 0.5vw;
            justify-content: space-between;
            .btn-delete {
                margin-right: 16px;
                background-color: $color-white;
                border: 1px solid $color-red;
                color: $color-text;
            }
        }
        .screen-employee-form-content {
            @include card($border-radius: 6px);
            padding: 0.5vw 1vw;
            h2 {
                font-size: 18px;
                font-weight: 700;
            }
            .row .col {
                width: calc(100% / 5 - 15px);
                margin-right: 15px;
                .inputs-dropdown-container {
                    .invalid__control {
                        border-radius: 6px;
                        box-shadow: 0 0 3px $color-red;
                        border-color: $color-white;
                    }
                }
            }
            .inputs-cover-photo-container {
                margin-right: 15px;
            }
            span .icon-required {
                display: inline-block;
                height: 6px;
                width: 6px;
                margin-bottom: 8px;
                margin-left: 4px;
            }
        }
    }
}
