@import "base";

.screen-employee-container {
    height: calc(100% - 53px);
    padding: 0.5vw 1vw;
    .screen-employee-inner-container {
        @include card($border-radius: 6px);
        height: 100%;
        padding: 0.5vw 1vw;
        .screen-employee-header {
            padding-bottom: 0.5vw;
            justify-content: space-between;
        }
        .screen-employee-content {
            flex: 1;
            @include card($border-radius: 6px);
            padding: 0.5vw 1vw;
            overflow: hidden;
            h2 {
                font-size: 18px;
                font-weight: 700;
            }
            .screen-employee-data {
                .row .col {
                    width: calc(100% / 5 - 15px);
                    margin-right: 15px;
                }
                .inputs-cover-photo-container {
                    margin-right: 15px;
                }
            }
            .screen-employee-stats {
                margin-top: 14px;
                border-top: 1px solid $color-text;
                height: calc(100% - 180px - 15px);
                > div {
                    height: calc(100% - 56px);
                }
            }
        }
    }
}
