@import "base";
.button-back-container {
    cursor: pointer;
    .icon-back {
        height: 25px;
        width: 25px;
        margin-right: 8px;
    }
    font-weight: 700;
    font-size: 22px;
    line-height: 32px;
    transform-origin: center;
}
