@import "base";

.popup-content.modal-container-content.UploadOdometerDataReason-content {
    width: auto;
    min-width: 500px;
    max-width: 50vw;
    .modal-upload-odometer-data-reason-container {
        padding: 0.5vw;
        .modal-upload-odometer-data-reason-header {
            justify-content: space-between;
            h2 {
                font-weight: 700;
                font-size: 22px;
            }
        }
        .modal-upload-odometer-data-reason-content {
            h3 {
                text-align: center;
                font-weight: 500;
            }
        }
        .modal-upload-odometer-data-reason-footer {
            justify-content: space-evenly;
            .cancel {
                background-color: $color-white;
                color: $color-blue;
                border: 1px solid $color-blue;
            }
        }
    }
}
