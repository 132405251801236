@import "base";

.screen-login-container {
    height: 100vh;
    justify-content: center;
    background-image: url("../../assets/images/login-background.png");
    background-position: center;
    background-repeat: no-repeat;
    .screen-login-inner-container {
        position: relative;
        @include card($border-radius: 6px);
        height: 95%;
        padding: 40px 120px;
        justify-content: center;
        max-width: 780px;
        min-width: 780px;
        .row.row-language {
            position: absolute;
            top: 10px;
            left: 10px;
            .icon {
                margin-right: 10px;
            }
            p {
                font-weight: 700;
                margin: 0;
            }
            .inputs-switch-container {
                margin: 4px 5px 0;
                .inputs-switch-inner-container {
                    height: 20px;
                    width: 40px;
                    .inputs-switch-circle {
                        height: 16px;
                        width: 16px;
                    }
                }
                &.switch-on .inputs-switch-circle {
                    left: calc(100% - 18px);
                }
            }
        }
        img {
            margin: auto;
            padding-bottom: 40px;
            max-height: 180px;
        }
        .screen-login-content {
            height: 100%;
            p {
                text-align: center;
                font-size: 1rem;
                line-height: 1.375rem;
                &.header {
                    text-transform: uppercase;
                    font-size: 1.25rem;
                    line-height: 1.75rem;
                    font-weight: 700;
                }
            }
            .screen-login-form {
                padding: 15px;
                span {
                    font-size: 0.875rem;
                }
                input:disabled {
                    border: none;
                    background: none;
                    text-align: center;
                    font-size: 1.25rem;
                    line-height: 1.75rem;
                }
                .add-margin {
                    margin-bottom: 15px;
                }
            }
        }
        .screen-login-footer {
            padding: 0 5% 7%;
            position: relative;
            .buttons-raised-container {
                padding: 0 20%;
            }
            p {
                position: absolute;
                bottom: -20%;
                left: 0;
                right: 0;
                text-align: center;
                font-size: 1rem;
                line-height: 1.375rem;
                .btn-edit {
                    cursor: pointer;
                    font-weight: 700;
                    text-decoration: underline;
                }
            }
        }
    }
}
