@import "base";

.inputs-datepicker-container {
    cursor: pointer;
    margin: 10px 0px;
    position: relative;
    &.invalid {
        box-shadow: none !important;
        .react-datepicker__input-container input {
            border: none !important;
            box-shadow: 0 0 3px $color-red !important;
        }
    }
    .react-datepicker-wrapper {
        width: 100%;
    }
    input {
        width: 100%;

        height: 36px;
        border: 1px solid rgba(0, 52, 112, 0.3);
        border-radius: 6px;
        padding-left: 10px;
        padding-right: 40px;
        text-overflow: ellipsis;
    }
    > .icon.icon-calendar,
    > .icon.icon-clock {
        pointer-events: none;
        width: 20px;
        height: 20px;
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        &:active {
            transform: translateY(-50%);
        }
    }
}
