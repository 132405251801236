@import "base";
.login-code-container {
  width: 100%;
  align-items: center;
  text-align: center;
//   padding-bottom: 10%;
  .code-container {
    padding: 15px 0;
    .code-container-inner {
      justify-content: space-around;
      padding: 25px 0;
      max-width: 400px;
      margin: auto;
      .digit-container {
        border: none;
        border-radius: 6px;
        min-width: 50px;
        min-height: 50px;
        margin: 0 10px;
        text-align: center;
        font-size: 1.375rem;
        font-weight: 700;
        border: 1px solid $color-gray;
        transition: 0.2s linear;
        &.invalid {
          border: 1px solid $color-red;
          color: $color-red;
        }
        &.valid {
          border: 1px solid $color-green;
          color: $color-green;
        }
      }
    }
  }
  p,
  .code-invalid,
  .code-resend {
    font-size: 1rem;
    line-height: 1.375rem;
  }
  .code-invalid {
    justify-content: center;
    min-height: 30px;
    margin-bottom: 0;
    color: $color-red;
  }
  .code-resend {
    cursor: pointer;
    color: $color-blue;
    font-weight: 700;
    text-decoration: underline;
    &:active {
      transform: scale(0.98);
    }
  }
}
