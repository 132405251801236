@import "base";

.screen-vehicle-container {
    height: calc(100% - 53px);
    padding: 0.5vw 1vw;
    .screen-vehicle-inner-container {
        @include card($border-radius: 6px);
        height: 100%;
        padding: 0.5vw 1vw;
        h2 {
            font-size: 20px;
            font-weight: 700;
            margin: 10px 0;
        }
        .screen-vehicle-header {
            padding-bottom: 0.5vw;
        }
        .screen-vehicle-content {
            height: calc(100% - 32px - 0.5vw);
        }
        .screen-vehicle-content-left {
            height: 100%;
            @include card($border-radius: 6px);
            flex: 1;
            max-width: 40%;
            > div {
                padding: 0.5vw 1vw;
                @include card($border-radius: 6px);
            }
            .screen-vehicle-main-container {
                z-index: 3;
                .screen-vehicle-main-header {
                    justify-content: space-between;
                    .icon {
                        margin-left: 15px;
                        @include shadow-large;
                        border-radius: 6px;
                        background-size: 70%;
                    }
                }
                .screen-vehicle-main-content {
                    p {
                        margin: 8px 0;
                        span {
                            font-weight: 700;
                        }
                    }
                }
            }
            .screen-vehicle-odometer-container {
                z-index: 2;
                > .row {
                    justify-content: space-between;
                    p {
                        margin: 6px 0;
                    }
                    p:nth-child(1) {
                        &.red {
                            color: $color-red;
                        }
                        span {
                            font-weight: 700;
                        }
                    }
                    p:nth-child(2) {
                        font-size: 14px;
                        color: gray;
                    }
                }
            }
            .screen-vehicles-odometer-history-container {
                z-index: 1;
                flex: 1;
                overflow: hidden;
                > .row {
                    justify-content: space-between;
                    margin-bottom: 10px;
                    .icon-plus {
                        border: 1px dashed $color-blue;
                        border-radius: 6px;
                        background-size: 70%;
                        margin-left: 16px;
                    }
                }
                .table-container {
                    .table-header,
                    .table-row-container {
                        padding-left: 2px;
                        padding-right: 2px;
                        font-size: 14px;
                    }
                }
            }
            // .screen-vehicle-additional-container {
            //     .screen-vehicle-files {
            //         .icon-plus {
            //             border: 1px dashed $color-blue;
            //             border-radius: 6px;
            //             background-size: 70%;
            //             margin-left: 16px;
            //         }

            //         border-bottom: 1px solid gray;
            //         padding-bottom: 40px;
            //         .row-files {
            //             flex-wrap: wrap;
            //             max-height: 150px;
            //             overflow: auto;
            //             max-width: 100%;
            //             .single-file {
            //                 margin-right: 16px;
            //                 margin-bottom: 10px;
            //                 a {
            //                     font-weight: 700;
            //                 }
            //             }
            //             .icon-remove {
            //                 height: 15px;
            //                 width: 15px;
            //                 margin-left: 5px;
            //             }
            //         }
            //     }
            //     .screen-vehicle-comments {
            //         padding-bottom: 40px;
            //     }
            // }
        }
        .screen-vehicle-sessions-container {
            @include card($border-radius: 6px);
            flex: 1.8;
            margin-left: 1vw;
            align-self: flex-start;
            max-height: 100%;
            .screen-vehicle-sessions-header {
                padding: 0.5vw 1vw;
                h2 {
                    margin-right: auto;
                }
                .row {
                    flex: 1;
                    justify-content: flex-end;
                }
                span {
                    margin-right: 16px;
                }
                .inputs-datepicker-container {
                    input {
                        min-width: 250px;
                    }
                    button.react-datepicker__close-icon::after {
                        background-color: $color-blue;
                        height: 20px;
                        width: 20px;
                    }
                    &.has-value {
                        > .icon {
                            right: 30px;
                        }
                        input {
                            padding-right: 60px;
                        }
                    }
                }
            }
            .screen-vehicle-sessions-content {
                .screen-vehicle-sessions-statistics-container {
                    @include card($border-radius: 6px);
                    margin-bottom: 20px;
                    padding: 0.5vw 1vw;
                    > .row {
                        &:not(:last-child) {
                            border-bottom: 1px solid $color-blue;
                        }
                        h2 {
                            flex-basis: 230px;
                            margin: 6px 0;
                        }
                        p {
                            margin: 6px 0;
                            font-size: 0.875rem;
                        }
                        .row p:not(:last-child) {
                            border-right: 1px solid $color-blue;
                            padding-right: 15px;
                        }
                        .row p:not(:first-child) {
                            margin-left: 15px;
                        }
                    }
                }

                flex: 1;
                max-height: calc(100% - 51px);
                overflow: auto;
                padding: 0.5vw 1vw;
                overflow: auto;
            }
        }
    }
}
