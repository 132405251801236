@import "base";

.screen-user-form-container {
    height: calc(100% - 53px);
    padding: 0.5vw 1vw;
    .screen-user-form-inner-container {
        @include card($border-radius: 6px);
        height: 100%;
        .screen-user-form-header {
            padding: 0.5vw 1vw;
            justify-content: space-between;
            .btn-delete {
                margin-right: 16px;
                background-color: $color-white;
                border: 1px solid $color-red;
                color: $color-text;
            }
        }
        .screen-user-form-content {
            overflow: auto;
            padding: 0.5vw 1vw;
            .screen-user-form {
                @include card($border-radius: 6px);
                margin-bottom: 15px;
                > div {
                    padding: 0.5vw 1vw;
                }
                h2 {
                    font-size: 20px;
                    font-weight: 700;
                    margin: 0;
                }
                > .row-header .icon-remove {
                    height: 20px;
                    width: 20px;
                    margin-left: auto;
                }

                > .row-form {
                    align-items: flex-end;
                    > .col {
                        width: calc(100% / 4 - 15px);
                        margin-right: 15px;
                        span .icon-required {
                            display: inline-block;
                            height: 6px;
                            width: 6px;
                            margin-bottom: 8px;
                            margin-left: 4px;
                        }
                    }
                    .inputs-cover-photo-container {
                        margin-right: 15px;
                    }
                }
                .row-role {
                    background-color: $color-whiteish;
                }
            }
        }

        .screen-user-form-footer {
            padding: 0 1vw;
            .buttons-raised-container {
                border: 1px dashed $color-blue;
                background-color: $color-white;
                color: $color-blue;
            }
        }
    }
}
