@import "base";

.screen-reservation-chat-container {
    height: calc(100% - 53px);
    padding: 0.5vw 1vw;
    .screen-reservation-chat-inner-container {
        @include card($border-radius: 6px);
        height: 100%;
        padding: 0.5vw 1vw;
        .screen-reservation-chat-header {
            margin-bottom: 0.5vw;
            .row-close {
                cursor: pointer;
                .icon-close {
                    height: 25px;
                    width: 25px;
                    margin-right: 8px;
                }
                font-weight: 700;
                font-size: 22px;
                line-height: 56px;
            }
        }
    }
    .row.empty {
        flex: 1;
        justify-content: center;
    }
    .screen-reservation-chat-content {
        flex: 1;
        overflow: auto;
        flex-direction: column-reverse;
        .days-divider {
            padding: 0 0.25vw;
            p {
                font-size: 1.125rem;
                font-weight: 500;
            }
            .line {
                height: 1px;
                width: 100%;
                background-color: $color-text;
                margin-left: 15px;
                margin-top: 6px;
            }
        }
        .message.me {
            align-self: flex-end;
            flex-direction: row-reverse;
            .message-content {
                justify-content: flex-end;
            }
            .text,
            .video,
            img {
                border: none;
                color: $color-white;
                background-color: $color-blue;
            }
        }
        .message {
            padding: 0 0.25vw;
            > p {
                margin-bottom: 0px;
                font-weight: 700;
                span {
                    color: $color-blue;
                }
            }
            .message-content {
                padding: 0.5vw 0;
            }
            .placeholder {
                width: 35px;
                min-width: 35px;
                height: 35px;
                border-radius: 50%;
                margin-right: 5px;
                &.logo {
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: cover;
                }
            }
            img {
                max-width: 200px;
                max-height: 400px;
                padding: 8px;
                border: 1px solid $color-text;
                border-radius: 6px;
                margin: 8px 0;
            }
            .text {
                border-radius: 6px;
                border: 1px solid $color-text;
                padding: 0.5vw 1vw;
                margin: 8px 0;
                white-space: pre-line;
            }
            .video {
                border-radius: 6px;
                border: 1px solid $color-text;
                margin: 8px 0;
                padding: 0.25vw 0.5vw;
            }
            span.time {
                font-size: 0.75rem;
                color: gray;
            }
        }
    }
}
