@import "base";

.screen-discount-code-container {
    height: calc(100% - 53px);
    padding: 0.5vw 1vw;
    .screen-discount-code-inner-container {
        @include card($border-radius: 6px);
        height: 100%;
        .screen-discount-code-header {
            padding: 0.5vw 1vw;
            justify-content: space-between;
            .button-back-container {
                height: 36px;
                margin: 10px 0;
            }
        }
        .screen-discount-code-content {
            height: calc(100% - 53px - 56px - 1vw);
        }
        .screen-discount-code-footer {
            height: 53px;
            @include card($background: $color-cream);
            .row-stats {
                @include card($background: $color-blue-light, $border-radius: 6px);
                p {
                    margin: 0;
                    padding: 0 16px;
                    line-height: 53px;
                    span {
                        font-weight: 700;
                    }
                    &:not(:last-child) {
                        border-right: 1px solid gray;
                    }
                }
            }
            .row-buttons {
                margin-left: auto;
                .icon-export {
                    @include shadow-large;
                    margin: 0 16px;
                    height: 40px;
                    width: 40px;
                }
            }
        }
    }
}
