@import 'base';

.screen-reservation-container {
  height: calc(100% - 53px);
  padding: 0.5vw 1vw;
  .screen-reservation-inner-container {
    @include card($border-radius: 6px);
    height: 100%;
    padding: 0.5vw 1vw;
    overflow-y: auto;
    .screen-reservation-header {
      padding-bottom: 0.5vw;
      justify-content: space-between;
      .btn-delete {
        margin-right: 16px;
        background-color: $color-white;
        border: 1px solid $color-red;
        color: $color-text;
      }
    }
    .screen-reservation-content {
      @include card($border-radius: 6px);
      padding: 0.5vw 1vw;
      h2 {
        font-size: 18px;
        font-weight: 700;
      }
      .divider {
        height: 1px;
        width: 100%;
        background-color: $color-text;
        margin-top: 15px;
      }
      > .vehicles-container > .row,
      > .row {
        flex-wrap: wrap;
        align-items: flex-end;
        > .col {
          width: calc(100% / 11 * 2 - 15px);
          margin-right: 15px;
          &.col-double {
            width: calc(100% / 11 * 4 - 15px);
          }
          &.col-half {
            width: calc(100% / 11 - 15px);
          }

          .inputs-dropdown-container {
            .invalid__control {
              border-radius: 6px;
              box-shadow: 0 0 3px $color-red;
              border-color: $color-white;
            }
          }
        }
      }
      > .vehicles-container > .row:not(:last-child) {
        margin-bottom: 15px;
      }
      span .icon-required {
        display: inline-block;
        height: 6px;
        width: 6px;
        margin-bottom: 8px;
        margin-left: 4px;
      }
    }
  }
}
