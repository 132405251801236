@import "base";

.screen-discount-code-form-container {
    height: calc(100% - 53px);
    padding: 0.5vw 1vw;
    .screen-discount-code-form-inner-container {
        @include card($border-radius: 6px);
        height: 100%;
        padding: 0.5vw 1vw;
        .screen-discount-code-form-header {
            padding-bottom: 0.5vw;
            justify-content: space-between;
            .btn-delete {
                margin-right: 16px;
                background-color: $color-white;
                border: 1px solid $color-red;
                color: $color-text;
            }
        }
        .screen-discount-code-form-content {
            @include card($border-radius: 6px);
            padding: 0.5vw 1vw;
            h2 {
                font-size: 18px;
                font-weight: 700;
            }
            .divider {
                height: 1px;
                width: 100%;
                background-color: $color-text;
                margin-top: 15px;
            }
            > .row {
                flex-wrap: wrap;
                align-items: flex-end;
                > .col {
                    width: calc(100% / 5 - 15px);
                    margin-right: 15px;
                    &.col-double {
                        width: calc(100% / 2.5 - 15px);
                        &.col-codesToGenerate {
                            .inputs-text-container {
                                width: 50%;
                            }
                            span.error {
                                color: $color-red;
                            }
                        }
                    }
                    &.col-half {
                        width: calc(100% / 10 - 15px);
                    }
                    .inputs-switch-container {
                        height: 38px;
                        width: 76px;
                        border: 1px solid rgba(0, 52, 112, 0.3);
                        border-radius: 6px;
                        margin-left: 15px;
                        &.switch-on .inputs-switch-circle {
                            left: calc(100% - 38px);
                        }
                        .inputs-switch-inner-container {
                            width: 76px;
                            height: 36px;
                            background-color: transparent;
                            padding: 0;
                            .label-on,
                            .label-off {
                                color: $color-text;
                                z-index: 1;
                                width: 38px;
                                text-align: center;
                                margin: 0;
                            }
                            .fade-out {
                                opacity: 1;
                                transform: none;
                                color: $color-white;
                                font-weight: 700;
                            }
                        }
                        .inputs-switch-circle {
                            background-color: $color-blue;
                            height: 38px;
                            width: 38px;
                            border-radius: 5px;
                            left: 0;
                        }
                    }
                    .inputs-dropdown-container {
                        .invalid__control {
                            border-radius: 6px;
                            box-shadow: 0 0 3px $color-red;
                            border-color: $color-white;
                        }
                    }
                }
            }
            span .icon-required {
                display: inline-block;
                height: 6px;
                width: 6px;
                margin-bottom: 8px;
                margin-left: 4px;
            }
        }
    }
}
