@import "base";

.popup-content.modal-container-content.UploadOdometerData-content {
    width: auto;
    min-width: 500px;
    max-width: 50vw;
    .modal-upload-odometer-data-container {
        padding: 0.5vw;
        .modal-upload-odometer-data-header {
            justify-content: space-between;
            h2 {
                font-weight: 700;
                font-size: 22px;
            }
        }
        .modal-upload-odometer-data-content {
            h3 {
                text-align: center;
                font-weight: 500;
            }
            > .col {
                margin: 10px 0;
            }
            .row-pictures {
                margin: 10px 0;
                > span.invalid {
                    color: $color-red;
                }
                .icon-plus {
                    border: 1px dashed $color-blue;
                    border-radius: 6px;
                    background-size: 70%;
                    margin: 0 16px;
                }
                .row-files {
                    flex-wrap: wrap;
                    .single-file {
                        margin: 10px 16px 10px 0;
                        a {
                            font-weight: 700;
                        }
                    }
                    .icon-remove {
                        height: 15px;
                        width: 15px;
                        margin-left: 5px;
                    }
                }
            }
        }
        .modal-upload-odometer-data-footer {
            justify-content: space-evenly;
            .cancel {
                background-color: $color-white;
                color: $color-blue;
                border: 1px solid $color-blue;
            }
        }
    }
}
