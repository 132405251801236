@import "base";

.screen-cart-history-container {
    height: calc(100% - 53px);
    padding: 0.5vw 1vw;
    .screen-cart-history-inner-container {
        @include card($border-radius: 6px);
        height: 100%;
        padding: 0.5vw 1vw;
        .screen-cart-history-header {
            padding-bottom: 0.5vw;
            justify-content: space-between;
        }
        .screen-cart-history-content {
            flex: 1;
            @include card($border-radius: 6px);
            // padding: 0.5vw 1vw;
            // h2 {
            //     font-size: 18px;
            //     font-weight: 700;
            // }
        }
    }
}
