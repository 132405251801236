@import "base";

.screen-session-container {
    height: calc(100% - 53px);
    padding: 0.5vw 1vw;
    .screen-session-inner-container {
        @include card($border-radius: 6px);
        height: 100%;
        padding: 0.5vw 1vw;
        .screen-session-header {
            padding-bottom: 0.5vw;
        }
        .screen-session-content {
            height: calc(100% - 32px - 0.5vw);
        }
        .screen-session-map {
            height: 100%;
            @include card($border-radius: 6px);
            flex: 1.5;
        }
        .screen-session-info-container {
            height: 100%;
            @include card($border-radius: 6px);
            flex: 1;
            margin-left: 1vw;
            > div {
                padding: 8px 16px;
            }
            .screen-session-info-header {
                justify-content: space-between;
                @include card($border-radius: 6px);
                h2 {
                    margin: 0;
                    font-weight: 700;
                    font-size: 20px;
                    text-transform: capitalize;
                }
                .is-online {
                    background-color: $color-green;
                    border-radius: 6px;
                    margin-left: 10px;
                    p {
                        margin: 0;
                        padding: 8px 12px;
                        font-size: 1rem;
                        line-height: 1.375rem;
                        font-weight: 700;
                        color: $color-white;
                    }
                    &.offline {
                        background-color: $color-red;
                    }
                }
            }
            .session-legend h2,
            .screen-session-info-comments h2 {
                margin: 0;
                margin-right: auto;
                font-size: 1rem;
                line-height: 1.875rem;
                font-weight: 700;
            }
            .screen-session-info-content {
                flex: 1;
                padding: 40px 16px;
                .screen-session-info-inner-content {
                    // justify-content: space-between;
                    flex-wrap: wrap;
                    .row {
                        background-color: $color-whiteish;
                        border-radius: 6px;
                        margin: 15px;
                        margin-left: 0;
                        padding: 0 15px;
                        p {
                            margin: 0;
                            padding: 10px 0;
                            font-size: 0.875rem;
                            line-height: 1.375rem;
                            span {
                                font-weight: 700;
                            }
                        }
                        .icon-profile {
                            height: 25px;
                            width: 25px;
                            margin-left: 16px;
                        }
                    }
                }
                .session-legend {
                    @include card($border-radius: 6px);
                    justify-content: flex-end;
                    padding: 0.5vw 1vw;
                    &.blur {
                        opacity: 0.5;
                    }
                    .range {
                        border: 2px solid $color-white;
                        border-radius: 6px;
                        padding: 5px 10px;
                        margin-left: 15px;
                        font-weight: 700;
                        font-size: 0.875rem;
                        &.blur {
                            opacity: 0.5;
                        }
                    }
                }
            }
            .screen-session-info-comments {
                border-top: 1px solid $color-blue;
                min-height: 30%;
            }
        }
    }
}

[role="tooltip"].popup-content.popup-session-online-container-content {
    width: 350px;
    padding: 0 24px;
    p.title {
        font-size: 1.125rem;
        line-height: 1.625rem;
        font-weight: 700;
        color: $color-green;
        &.offline {
            color: $color-red;
        }
    }
    .row p {
        font-weight: 700;
    }
}
