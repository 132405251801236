@import "base";
.fleet-map-container {
    position: relative;
    .marker {
        height: 30px;
        width: 30px;
        border-radius: 50%;
        background-color: $color-white;
        border: 2px solid black;
        display: flex;
        align-items: center;
        justify-content: center;
        .icon {
            background-size: 70%;
        }
        &.active {
            border-color: $color-green;
        }
    }
    .vehicles-without-data-container {
        position: absolute;
        bottom: 0;
        width: 100%;
        // justify-content: flex-end;
        padding: 8px 16px;
        @include card($border-radius: 6px);
        font-weight: 500;
        .scroll-container {
            flex: 1;
            overflow: auto;
            margin-left: 16px;
            .marker {
                height: 25px;
                width: 25px;
                margin-right: 8px;
            }
        }
    }
    .mapboxgl-ctrl-logo {
        display: none;
    }

    .mapboxgl-popup {
        .mapboxgl-popup-tip,
        .mapboxgl-popup-close-button {
            display: none;
        }
        .mapboxgl-popup-content {
            padding: 0;
            border-radius: 6px;
        }
    }
}

.popup-content.popup-leet-marker-popup-container-content {
    padding: 0;
    h2 {
        margin: 0;
    }
}
.popup-content .fleet-marker-popup-container,
.fleet-map-container .fleet-marker-popup-container {
    @include card($border-radius: 6px);
    padding: 4px 8px;
    h2 {
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 0;
    }
    p {
        margin-top: 4px;
    }
    &.active {
        background-color: $color-green;
        h2,
        p {
            color: $color-white;
        }
    }
}
