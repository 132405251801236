@import "base";

.screen-cart-form-container {
    height: calc(100% - 53px);
    padding: 0.5vw 1vw;
    &.cart-group-form .screen-cart-form-inner-container {
        .screen-cart-form-header .row-buttons,
        .screen-cart-form-content > *:not(.cart-group-form-container) {
            pointer-events: none;
            opacity: 0.3;
        }
        .screen-cart-form-content .row-files {
            border-top: none;
            margin-top: 0;
        }
    }

    .screen-cart-form-inner-container {
        @include card($border-radius: 6px);
        height: 100%;
        padding: 0.5vw 1vw;
        .screen-cart-form-header {
            padding-bottom: 0.5vw;
            justify-content: space-between;
            .btn-delete {
                margin-right: 16px;
                background-color: $color-white;
                border: 1px solid $color-red;
                color: $color-text;
            }
        }
        .screen-cart-form-content {
            @include card($border-radius: 6px);
            padding: 0.5vw 0;
            > div:not(.cart-group-details):not(.row-files),
            > h2 {
                padding: 0 1vw;
            }
            h2 {
                font-size: 18px;
                font-weight: 700;
            }
            .row .col {
                width: calc(100% / 5 - 15px);
                margin-right: 15px;
                .inputs-dropdown-container {
                    .invalid__control {
                        border-radius: 6px;
                        box-shadow: 0 0 3px $color-red;
                        border-color: $color-white;
                        // .inputs-text,
                        // .label-container {
                        //     border-color: $color-white;
                        // }
                    }
                }
            }
            span .icon-required {
                display: inline-block;
                height: 6px;
                width: 6px;
                margin-bottom: 8px;
                margin-left: 4px;
            }
            .working-hours-container {
                padding: 20px 0;
                > .row {
                    flex-wrap: wrap;
                    padding-top: 10px;
                }
                .single-day-container {
                    width: calc(25%);
                    margin-bottom: 10px;
                    > span {
                        flex: 1;
                        text-transform: capitalize;
                    }
                    .inputs-text-container {
                        margin: 0 5px;
                        width: 62px;
                    }
                    .placeholder {
                        flex: 0.2;
                    }
                    &:not(:nth-child(4n - 3)) {
                        padding-left: 15px;
                    }
                    &:not(:nth-child(4n)):not(:last-child) {
                        border-right: 1px solid gray;
                    }

                    .inputs-datepicker-container {
                        margin: 0 5px;
                        width: 92px;
                    }
                }
            }
            .cart-group-form-container {
                margin: 0 1vw;
                padding: 0;
                border-top: 1px solid $color-text;
                border-bottom: 1px solid $color-text;
            }
            .cart-group-details {
                background-color: $color-cream;
                padding: 0.5vw 1vw;
                .icon-pen {
                    background-color: $color-blue-light;
                    border-radius: 50%;
                    margin-left: 40px;
                    background-size: 50%;
                }
                .col {
                    flex: 1;
                    > .row {
                        &:not(:last-child) {
                            border-bottom: 1px solid gray;
                            margin-bottom: 0.5vw;
                            padding-bottom: 0.5vw;
                        }
                        > span {
                            font-weight: 300;
                            font-size: 14px;
                            white-space: nowrap;
                            margin-right: 10px;
                            span {
                                font-weight: 500;
                            }
                        }
                    }
                }
                .line {
                    height: 15px;
                    width: 1px;
                    background-color: $color-text;
                    margin-right: 10px;
                }
                .working-hour-single {
                    text-transform: capitalize;
                    span {
                        margin-left: 5px;
                    }
                }
            }
            .row-files {
                border-top: 1px solid $color-text;
                border-bottom: 1px solid $color-text;
                margin: 20px 1vw;
                .icon-plus {
                    border: 1px dashed $color-blue;
                    border-radius: 6px;
                    background-size: 70%;
                    margin: 0 16px;
                }
                .single-file {
                    margin-right: 16px;
                    .icon-remove {
                        height: 12px;
                        width: 12px;
                        margin-left: 5px;
                    }
                }
            }
        }
    }
}
