@import "base";

.screen-users-container {
    height: calc(100% - 53px);
    padding: 0.5vw 1vw;
    .screen-users-inner-container {
        @include card($border-radius: 6px);
        height: 100%;
        padding: 0.5vw 1vw;
        align-items: flex-start;
        .screen-users-left-container {
            @include card($border-radius: 6px);
            padding: 0.5vw 1vw;
            .screen-users-left-header .row {
                justify-content: space-between;
                h2 {
                    font-size: 22px;
                    font-weight: 700;
                    margin-bottom: 0;
                }
                .icon-pen {
                    border: 1px solid $color-blue;
                    border-radius: 6px;
                    background-size: 75%;
                }
            }
            .screen-users-left-content {
                .icon-avatar {
                    height: 80px;
                    width: 80px;
                    @include shadow-large;
                    border-radius: 6px;
                    margin-right: 32px;
                    background-size: cover;
                }
                .col {
                    flex: 1;
                }

                p {
                    flex: 1;
                    background-color: $color-whiteish;
                    border-radius: 6px;
                    margin: 15px;
                    margin-left: 0;
                    padding: 5px 8px;
                    span {
                        font-weight: 700;
                    }
                }
            }
        }
        .screen-users-right-container {
            @include card($border-radius: 6px);
            height: 100%;
            margin-left: 1vw;
            flex: 1;
            .screen-users-right-header {
                justify-content: space-between;
                padding: 0.5vw 1vw;
                h2 {
                    font-size: 22px;
                    font-weight: 700;
                    margin: 0;
                }
            }
            .screen-users-right-content {
                height: calc(100% - 56px - 2vw);
            }
        }
    }
}
