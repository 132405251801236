@import "../../base";

.overlay-container {
  position: absolute;
  background: transparentize(#000, 0.5);
  width: 100vw;
  height: 100vh;
  top: 0;
  bottom: 0;
  right: 0;
  transition: 0.4s linear;
  opacity: 0;
  z-index: -1;
  .overlay-inner-container {
    @include card;
    border-radius: 6px;
    width: 0;
    height: calc(100vh - 1vw - 53px);
    margin-top: calc(0.5vw + 53px);
    margin-left: auto;
    transform: translateX(-1vw);
    transition: 0.3s ease-in;
  }
  &.open {
    opacity: 1;
    z-index: 100;
    .overlay-inner-container {
      width: 45vw;
    }
  }
}
