$color-white: #ffffff;
$color-whiteish: #fafafa;
$color-cream: #f9f9f9;
$color-gray: #bababa;
$color-blue: #0099ff;
$color-blue-light: #cbe1f0;
$color-red: #fa4460;
$color-green: #00ce14;
$color-purple: #502fc6;
$color-purple-light: #8a35ff;


$color-text: #1d1d23;
$colors-shadow: #00347026;

@mixin shadow-small {
    box-shadow: 0px 1px 4px 0px $colors-shadow;
}
@mixin shadow-large {
    box-shadow: 0px 5px 15px 0px $colors-shadow;
}

%flex {
    display: flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
}

@mixin card($border-radius: 0 0 6px 6px, $background: $color-white) {
    @include shadow-large;
    background: $background;
    border-radius: $border-radius;
}

@mixin remove-scrollbars {
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }
}

@font-face {
    font-family: "CorsaGrotesk";
    src: url("assets/fonts/CorsaGrotesk-Thin.ttf") format("truetype");
    font-style: normal;
    font-weight: 100;
}
@font-face {
    font-family: "CorsaGrotesk";
    src: url("assets/fonts/CorsaGrotesk-Light.ttf") format("truetype");
    font-style: normal;
    font-weight: 300;
}
@font-face {
    font-family: "CorsaGrotesk";
    src: url("assets/fonts/CorsaGrotesk-Regular.ttf") format("truetype");
    font-style: normal;
    font-weight: 400;
}
@font-face {
    font-family: "CorsaGrotesk";
    src: url("assets/fonts/CorsaGrotesk-Medium.ttf") format("truetype");
    font-style: normal;
    font-weight: 500;
}
@font-face {
    font-family: "CorsaGrotesk";
    src: url("assets/fonts/CorsaGrotesk-Bold.ttf") format("truetype");
    font-style: normal;
    font-weight: 700;
}
