@import "base";

.screen-reservation-notes-container {
    height: calc(100% - 53px);
    padding: 0.5vw 1vw;

    .screen-reservation-notes-inner-container {
        @include card($border-radius: 6px);
        height: 100%;
        padding: 0.5vw 1vw;
        .screen-reservation-notes-header {
            margin-bottom: 0.5vw;
            .row-close {
                cursor: pointer;
                .icon-close {
                    height: 25px;
                    width: 25px;
                    margin-right: 8px;
                }
                font-weight: 700;
                font-size: 22px;
                line-height: 56px;
            }
        }
    }
    .row.empty {
        flex: 1;
        justify-content: center;
    }
    .screen-reservation-notes-content {
        flex: 1;
        overflow: auto;
        flex-direction: column-reverse;
        .days-divider {
            padding: 0 0.25vw;
            p {
                font-size: 1.125rem;
                font-weight: 500;
            }
            .line {
                height: 1px;
                width: 100%;
                background-color: $color-text;
                margin-left: 15px;
                margin-top: 6px;
            }
        }
        .message {
            &.is-edit {
                pointer-events: none;
                opacity: 0.5;
            }
            > p {
                margin-bottom: 0px;
                font-weight: 700;
            }
            .message-content {
                padding: 0.5vw 0;
                position: relative;
                .icon.icon-options {
                    position: absolute;
                    right: 1vw;
                    top: 50%;
                    transform: translateY(-50%);
                    height: 20px;
                    width: 20px;
                }
            }
            .placeholder {
                width: 35px;
                min-width: 35px;
                height: 35px;
                border-radius: 50%;
                margin-right: 5px;
                &.logo {
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: cover;
                }
            }
            img {
                flex: 1;
                max-width: 200px;
                max-height: 400px;
                padding: 8px;
                border: 1px solid $color-text;
                border-radius: 6px;
                margin: 8px 0;
            }
            .text {
                flex: 1;
                border-radius: 6px;
                border: 1px solid $color-text;
                padding: 0.5vw 1vw;
                margin: 8px 0;
                white-space: pre-line;
            }
            .video {
                flex: 1;
                border-radius: 6px;
                border: 1px solid $color-text;
                margin: 8px 0;
                padding: 0.25vw 0.5vw;
            }
            span.time {
                font-size: 0.75rem;
                margin-left: 15px;
                color: gray;
            }
        }
    }
}

[role="tooltip"].popup-content.popup-screen-reservation-notes-options-container-content {
    width: auto;
    padding: 0;
    .popup-screen-reservation-notes-options-header {
        justify-content: space-between;
        @include card;
        padding: 10px 15px;
        h3 {
            margin: 0;
            font-weight: 700;
            font-size: 18px;
            line-height: 22px;
        }
        .icon-close {
            height: 18px;
            width: 18px;
        }
    }
    .popup-screen-reservation-notes-options-content {
        padding: 0 15px;
        .line {
            height: 1px;
            width: 100%;
            background-color: gray;
        }
        .row-action {
            cursor: pointer;
            .icon {
                margin-right: 15px;
            }
            p {
                font-weight: 700;
            }
            &:active {
                transform: scale(0.98);
            }
        }
    }
}
