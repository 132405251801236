@import "base";

.cart-group-form-container {
    padding: 0 1vw;
    .cart-group-form-header {
        // justify-content: space-between;
        padding-bottom: 20px;
        h2 {
            font-size: 22px;
            font-weight: 700;
        }
        .icon.icon-close {
            height: 20px;
            width: 20px;
            margin-right: 16px;
        }
    }
    .cart-group-form-content {
        .row .col {
            width: calc(100% / 4 - 15px);
            &.col-double{
                width: calc(100% / 4 * 2 - 15px);
            }
            margin-right: 15px;
            .inputs-switch-container {
                margin-left: 15px;
                .inputs-switch-inner-container {
                    background-color: #cbe1f0;
                }
                .label-on,
                .label-off {
                    color: $color-text;
                }
            }
        }
        .working-hours-container {
            padding: 20px 0;
            > .row {
                flex-wrap: wrap;
                padding-top: 10px;
            }
            .single-day-container {
                width: calc(33%);
                margin-bottom: 10px;
                > span {
                    flex: 1;
                    text-transform: capitalize;
                }
                .inputs-text-container {
                    margin: 0 5px;
                    width: 62px;
                }
                .placeholder {
                    flex: 0.2;
                }
            }
        }
        span .icon-required {
            display: inline-block;
            height: 6px;
            width: 6px;
            margin-bottom: 8px;
            margin-left: 4px;
        }
    }
    .cart-group-form-footer {
        justify-content: flex-end;
        .btn-delete {
            background-color: $color-white;
            border: 1px solid $color-red;
            color: $color-text;
            margin-right: 15px;
        }
    }
}
