@import "base";
.inputs-text-container {
    margin: 10px 0;
    .inputs-text {
        width: 100%;
        flex: 1;
        border-radius: 5px;
        padding: 9px 11px;
        border: 1px solid rgba(0, 52, 112, 0.3);
        color: $color-text;
        font-size: 13px;
        height: 38px;
    }
    &.with-label {
        .inputs-text {
            border-right: 0;
            border-radius: 6px 0 0 6px;
        }
        .label-container {
            border: 1px solid rgba(0, 52, 112, 0.3);
            border-left: none;
            border-radius: 0 6px 6px 0;
            background-color: $color-white;
            height: 38px;
        }
        p {
            padding: 0 8px;
            border-left: 1px solid rgba(0, 52, 112, 0.3);
            font-size: 13px;
            font-weight: 300;
        }
    }
    &.invalid {
        border-radius: 6px;
        box-shadow: 0 0 3px $color-red;
        .inputs-text,
        .label-container {
            border-color: $color-white;
        }
    }
}
