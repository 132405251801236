@import "base";
.popup-content.modal-loading-container-content {
    width: 770px;
    height: 470px;

    text-align: center;

    border: none;
    box-shadow: 0px 2px 3px 0px rgba(50, 50, 50, 0.3);
    padding: 0px;
    border-radius: 15px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    .modal-loading-text {
        font-size: 25px;
        font-weight: 700;
        padding-bottom: 45px;
    }
}
