@import "base";

.buttons-raised-container {
    position: relative;
    overflow: hidden;

    display: flex;
    align-items: center;
    justify-content: center;

    height: 36px;
    padding: 0px 25px;
    margin: 10px 0px;

    background-color: $color-blue;
    box-shadow: 0px 1px 4px rgba(0, 52, 112, 0.15);
    border-radius: 5px;

    font-family: "CorsaGrotesk", sans-serif;
    color: $color-white;
    font-weight: 500;
    font-size: 14px;

    cursor: pointer;
    &.disabled {
        pointer-events: none;
        opacity: 0.5;
    }

    &:active{
        transform: scale(0.99);
    }
    .circle {
        position: absolute;
        background-color: white;
        opacity: 0.6;
        width: 100px;
        height: 100px;
        border-radius: 50%;
        transform: translate(-50%, -50%) scale(0);
        animation: scale 0.5s ease-out;
    }
}
@keyframes scale {
    to {
        transform: translate(-50%, -50%) scale(3);
        opacity: 0;
    }
}
