@import "base";

html {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  outline: none;
}

html,
body,
#root {
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

#root {
  background-color: $color-whiteish;
}

html,
body,
#root,
p,
h1,
h2,
h3,
h4,
h5,
h6,
a,
li,
div,
a,
input,
textarea {
  font-family: "CorsaGrotesk", sans-serif;
  color: $color-text;
  font-weight: 400;
}

.row {
  @extend %flex;
  flex-direction: row;
  align-items: center;
}
.col {
  @extend %flex;
  flex-direction: column;
}

body .icon {
  height: 30px;
  width: 30px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  cursor: pointer;
  &.icon-add-photo {
    background-image: url("./assets/icons/add-photo.svg");
  }
  &.icon-arrow-down {
    background-image: url("./assets/icons/arrow-down.svg");
  }
  &.icon-avatar {
    background-image: url("./assets/images/avatar.png");
    border-radius: 50%;
  }
  &.icon-back {
    background-image: url("./assets/icons/back.svg");
  }
  &.icon-battery-red {
    background-image: url("./assets/icons/battery-red.svg");
  }
  &.icon-battery {
    background-image: url("./assets/icons/battery.svg");
  }
  &.icon-calendar {
    background-image: url("./assets/icons/calendar.svg");
  }
  &.icon-camera {
    background-image: url("./assets/icons/camera.svg");
  }
  &.icon-center {
    background-image: url("./assets/icons/center.svg");
  }
  &.icon-check {
    background-image: url("./assets/icons/check.svg");
  }
  &.icon-clock {
    background-image: url("./assets/icons/clock.svg");
  }
  &.icon-close {
    background-image: url("./assets/icons/close.svg");
  }
  &.icon-delete {
    background-image: url("./assets/icons/delete.svg");
  }
  &.icon-edit {
    background-image: url("./assets/icons/edit.svg");
  }
  &.icon-export {
    background-image: url("./assets/icons/export.svg");
  }
  &.icon-logout {
    background-image: url("./assets/icons/logout.svg");
  }
  &.icon-notes {
    background-image: url("./assets/icons/notes.svg");
  }
  &.icon-open {
    background-image: url("./assets/icons/open.svg");
  }
  &.icon-options {
    background-image: url("./assets/icons/options.svg");
  }
  &.icon-path {
    background-image: url("./assets/icons/path.svg");
  }
  &.icon-pause {
    background-image: url("./assets/icons/pause.svg");
  }
  &.icon-pen-white {
    background-image: url("./assets/icons/pen-white.svg");
  }
  &.icon-pen {
    background-image: url("./assets/icons/pen.svg");
  }
  &.icon-play {
    background-image: url("./assets/icons/play.svg");
  }
  &.icon-plus {
    background-image: url("./assets/icons/plus.svg");
  }
  &.icon-profile {
    background-image: url("./assets/icons/profile.svg");
  }
  &.icon-remove {
    background-image: url("./assets/icons/remove.svg");
  }
  &.icon-required {
    background-image: url("./assets/icons/required.svg");
  }
  &.icon-send {
    background-image: url("./assets/icons/send.svg");
  }
  &.icon-support {
    background-image: url("./assets/icons/support.svg");
  }
  &.icon-users {
    background-image: url("./assets/icons/users.svg");
  }
  &.icon-vehicle-green {
    background-image: url("./assets/icons/vehicle-green.svg");
  }
  &.icon-vehicle-white {
    background-image: url("./assets/icons/vehicle-white.svg");
  }
  &.icon-vehicle {
    background-image: url("./assets/icons/vehicle.svg");
  }
  &.icon-attach {
    background-image: url("./assets/icons/attach.svg");
  }
  &.icon-arrow-right-white {
    background-image: url("./assets/icons/arrow-right-white.svg");
  }
  &.icon-info {
    background-image: url("./assets/icons/info.svg");
  }
  &.icon-history {
    background-image: url("./assets/icons/history.svg");
  }
  &.icon-comment {
    background-image: url("./assets/icons/comment.svg");
  }
  &.icon-picture {
    background-image: url("./assets/icons/picture.svg");
  }
  &.icon-language {
    background-image: url("./assets/icons/language.svg");
  }
  &:active {
    transform: scale(0.95);
  }
  &.no-active {
    cursor: default;
    &:active {
      transform: scale(1);
    }
  }
  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
    &:active {
      transform: scale(1);
    }
  }
  &.no-pointer {
    cursor: default;
  }
}
